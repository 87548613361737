// import routes from '../router/routes'

import { showToast } from 'vant';
import dateBox from '@/components/dateBox';
import moment from 'moment';
import store from '@/store/index.js';
export default {
  name: 'index',
  components: {
    dateBox
  },
  data() {
    return {
      xfFrom: {
        hospitalId: 1,
        scaleId: 1,
        userId: []
      },
      dialogXflb: false,
      step: 1,
      showAll: false,
      isEdit: false,
      showPicker: false,
      showHos: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: '选项一'
      }, {
        text: '选项二'
      }, {
        text: '选项三'
      }],
      activeNames: ['1'],
      user: {},
      myinfo: {
        pregnancyState: 0,
        terminationDate: '',
        finishDate: ''
      },
      defaultinfo: {
        avater: '1',
        remarks: '1',
        names: '',
        idCard: '',
        menstruation: '1',
        hospitalId: '1'
      },
      myplans: [{
        id: 1,
        name: '11111'
      }, {
        id: 2,
        name: '222222'
      }, {
        id: 3,
        name: '3333333'
      }],
      sex: ['未知', '男', '女'],
      planTemp: {},
      hos: [{
        id: 1,
        name: '南充市身心医院'
      }],
      rules: {
        idCardNumber: [{
          required: true,
          message: '身份证不能为空',
          trigger: 'blur'
        }],
        ty: [{
          required: true,
          message: '身份证不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    edit() {
      console.log(123);
      this.isEdit = true;
    },
    PickerConfirm(value, v2) {
      console.log(value);
      this.showPicker = false;
      this.myinfo.deliverDate = formatDate(value);
      // that.$toast(action.name);
    },

    getHos() {
      var that = this;
      that.$http.get('/api/ycf/getHos', null, '正在检索').then(result => {
        console.log(result);
        if (result.data) {
          console.log(result);
          result.data.forEach(element => {
            element.text = element.hospital;
            element.value = element.id;
          });
          this.hos = result.data;
        } else {
          //this.showQYWXCode();
        }
      });
    },
    HosConfirm({
      selectedOptions
    }) {
      this.myinfo.hospitalId = selectedOptions[0].value;
      this.myinfo.hospital = selectedOptions[0].text;
      this.showHos = false;
    },
    save() {
      var that = this;
      this.$refs.form.validate().then(() => {
        that.$http.post('/api/ycf/updateInfo', this.$data.myinfo, '正在提交数据').then(result => {
          console.log(result);
          if (result.data) {
            var userstr = localStorage.getItem('user');
            var user = JSON.parse(userstr);
            user.userinfo = result.data;
            store.commit('setUserInfo', user);
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx89593e140fa7b55c&' + 'redirect_uri=http://hscj.ncsxyy.com/jump-ycf.html&' + 'response_type=code&scope=snsapi_userinfo&state=state#wechat_redirect';
            // this.$router.push('/index');
          } else {
            showToast('提交没有成功，请重试！');
          }
        });
      });
    },
    // date为相加前的时间， days 为 需要相加的天数
    addDate(date, days) {
      var date = new Date(date);
      date.setDate(date.getDate() + days);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var mm = "'" + month + "'";
      var dd = "'" + day + "'";
      if (mm.length == 3) {
        month = '0' + month;
      }
      if (dd.length == 3) {
        day = '0' + day;
      }
      var time = year + '-' + month + '-' + day;
      return time;
    }
  },
  created() {},
  mounted() {
    console.log('mounted');
    var userstr = localStorage.getItem('user');
    var user = JSON.parse(userstr);
    if (user.userinfo) {
      this.myinfo.pregnancyState = user.userinfo.pregnancyState;
      this.myinfo.terminationDate = user.userinfo.terminationDate;
      this.myinfo.finishDate = user.userinfo.finishDate;
    }
  },
  watch: {
    'myinfo.menstruation': function (newval) {
      // this.myinfo.deliverDate = moment(
      //   new Date(new Date(newval) + 280 * 24 * 60 * 60 * 1000)
      // ).format('yyyy-MM-DD');
      this.myinfo.deliverDate = this.addDate(newval, 280);
      console.log(this.myinfo.deliverDate);
    },
    'myinfo.deliverDate': function (newval) {
      // this.myinfo.deliverDate = moment(
      //   new Date(new Date(newval) + 280 * 24 * 60 * 60 * 1000)
      // ).format('yyyy-MM-DD');
      this.myinfo.menstruation = this.addDate(newval, -280);
      console.log(this.myinfo.menstruation);
    }
  }
};